<template>
  <div class="placeholderMap">
    <div :class="`placeholderImage_${placeholderType}`">
      <el-image
        style="width: 100%; height: 100%"
        :src="
          require(`../../../assets/images/newPlaceHolder/placeholderImage_${placeholderType}.png`)
        "
        :fit="'contain'"
      >
      </el-image>
    </div>
    <div class="placeholderText" v-if="placeholderText">
      {{ placeholderText }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    // 占位图的类型（0：没有评论；1：没有搜索结果灰色版；2：没有搜索结果灰色版(小)）
    placeholderType: {
      type: Number,
      default: 0,
    },
    placeholderText: {
      type: String,
      default: "",
    },
  },
};
</script>
<style lang="stylus" scoped>
.placeholderMap
  width 100%;
  height 100%;
  display flex;
  align-items center;
  justify-content center;
  flex-direction: column;
  .placeholderImage_0
    width 105px;
    height 57px;
  .placeholderImage_1
    width 112.8px;
    height 73.6px;
  .placeholderImage_2
    width 103px;
    height 67px;
  .placeholderText
    font-size 16px;
    color #808080;
    margin-top: 5px;
</style>
