var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "personItem",
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.selectThisElement.apply(null, arguments)
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "avatarContainer" },
        [
          _c("Avatar", {
            attrs: {
              path: _vm.itemInfo.profilePic,
              international:
                _vm.itemInfo.countryCode !== ""
                  ? Number(_vm.itemInfo.countryCode)
                  : 0,
              isGroupHeader: _vm.itemInfo.chatType == "groupChat",
              disableClick: true
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "itemContainer" }, [
        _c("div", { staticClass: "containerInner" }, [
          _c("div", { staticClass: "innerFirstLine" }, [
            _c(
              "div",
              {
                staticClass: "itemName",
                class: _vm.itemInfo.vipStatus ? "vipName" : "",
                style: {
                  maxWidth: _vm.itemInfo.chatType == "groupChat" ? "80%" : ""
                }
              },
              [
                _vm._v(
                  "\n          " + _vm._s(_vm.itemInfo.name) + "\n        "
                )
              ]
            ),
            _vm.itemInfo.vipStatus
              ? _c(
                  "div",
                  {
                    staticClass: "vipIcon",
                    staticStyle: { display: "flex", "align-items": "center" }
                  },
                  [
                    _c("el-image", {
                      staticStyle: { width: "16px", "margin-top": "8px" },
                      attrs: {
                        src: require("../../../assets/images/pay/masonry.png"),
                        fit: "fill"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.tags !== 0 && _vm.itemInfo.chatType == "chat"
              ? _c(
                  "div",
                  { staticClass: "tagsBox" },
                  _vm._l(_vm.tags, function(tag) {
                    return _c("div", { key: tag, staticClass: "tagItem" }, [
                      _vm._v(_vm._s(tag))
                    ])
                  }),
                  0
                )
              : _vm._e()
          ]),
          _vm.itemInfo.chatType == "chat"
            ? _c("div", { staticClass: "innerSecondLineForPerson" }, [
                _vm._v(
                  "\n        ID: " + _vm._s(_vm.itemInfo.ucId) + "\n      "
                )
              ])
            : _vm._e(),
          _vm.itemInfo.chatType == "groupChat"
            ? _c("div", { staticClass: "innerSecondLineForGroup" }, [
                _c("span", {
                  staticClass: "iconfont_Me icon-user-fill iconStyle"
                }),
                _vm._v(
                  "\n        " + _vm._s(_vm.itemInfo.memberCount) + "\n      "
                )
              ])
            : _vm._e()
        ])
      ]),
      _c(
        "div",
        {
          staticClass: "selectContainer",
          class: _vm.itemInfo.isSelected ? "hasSelected" : ""
        },
        [
          _vm.itemInfo.isSelected
            ? _c("span", { staticClass: "iconfont_Me icon-check iconStyle" })
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }