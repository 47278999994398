<template>
  <div class="containerBody">
    <div class="searchInputOuter">
      <div class="searchInputBox">
        <el-input
          class="inputStyle"
          v-model="searchKey"
          :placeholder="searchPlaceholder"
          @input="triggerSearchEvent"
        >
        </el-input>
      </div>
    </div>
    <template v-if="handleFriendsList.length !== 0">
      <div
        class="friendContainer"
        v-loading="friendsListLoading"
        element-loading-spinner="el-icon-loading"
        element-loading-background="#FFFFFF"
      >
        <template v-for="item in handleFriendsList">
          <div
            class="blockTitle"
            :key="item.name_first_spell"
            v-if="item.name_first_spell != 'self'"
          >
            <span class="titleText">{{ item.name_first_spell }}</span>
          </div>
          <div class="childrenContainer">
            <template v-for="(children, index) in item.childrens">
              <div
                :key="index"
                class="childrenItemContainer"
                @click.stop="selectedThisFriend(children)"
              >
                <div class="childrenItem">
                  <div class="leftContainer">
                    <div class="avatarContainer">
                      <Avatar
                        :path="children.avatar"
                        :international="
                          children.country !== '' ? Number(children.country) : 0
                        "
                        :disableClick="true"
                      >
                      </Avatar>
                    </div>
                    <div class="friendInfoContainer">
                      <div class="nameAndTagsContainer">
                        <div
                          class="nameContainer"
                          :class="children.vipStatus ? 'vipName' : ''"
                        >
                          {{ children.userName }}
                        </div>

                        <div
                          class="vipIcon"
                          style="display:flex;align-items:center"
                          v-if="children.vipStatus"
                        >
                          <el-image
                            style="width: 16px;margin-top: 8px;"
                            :src="
                              require('../../../assets/images/pay/masonry.png')
                            "
                            fit="fill"
                          >
                          </el-image>
                        </div>

                        <div
                          class="tagsContainer"
                          v-if="children.tags && children.tags.length !== 0"
                        >
                          <div
                            class="tagsItem"
                            v-for="tag in children.tags"
                            :key="tag"
                          >
                            {{ tag }}
                          </div>
                        </div>
                      </div>
                      <div class="IDContainer">
                        ID:&nbsp;{{ children.uc_id }}
                      </div>
                    </div>
                  </div>
                  <div class="rightContainer">
                    <div
                      class="selectContainer"
                      :class="children.isSelected ? 'hasSelected' : ''"
                    >
                      <span
                        v-if="children.isSelected"
                        class="iconfont_Me icon-check iconStyle"
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </template>
      </div>
    </template>
    <div v-else class="placeholderMap">
      <PlaceholderMap :placeholderType="2"></PlaceholderMap>
    </div>
  </div>
</template>
<script>
import Avatar from "../avatarDisplay";
import PlaceholderMap from "../placeholderMap";
export default {
  components: {
    Avatar,
    PlaceholderMap,
  },
  props: {
    // 监听是否可以开始加载数据
    readyToLoadData: {
      type: Boolean,
      default: false,
    },
    // 好友列表
    friendsList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  computed: {
    // 处理过的好友列表
    handleFriendsList() {
      let handleList = this.processedFriendsList.reduce((item, next) => {
        if (item.length !== 0) {
          if (
            item.findIndex(
              (value) => value.name_first_spell === next.name_first_spell
            ) === -1
          ) {
            item.push({
              name_first_spell: next.name_first_spell,
              childrens: [next],
            });
          } else {
            item[
              item.findIndex(
                (value) => value.name_first_spell === next.name_first_spell
              )
            ].childrens.push(next);
          }
        } else {
          item.push({
            name_first_spell: next.name_first_spell,
            childrens: [next],
          });
        }
        return item;
      }, []);
      return handleList;
    },
    userInfo() {
      return this.$store.getters.userInfo;
    },
  },
  watch: {
    readyToLoadData: {
      handler(val, old) {
        if (val && val !== old) {
          this.friendsListLoading = true;
          this.$emit(
            "getFriendList",
            () => {
              this.friendsListLoading = false;
            },
            () => {
              this.friendsListLoading = false;
            }
          );
        }
      },
      deep: true,
    },
    friendsList: {
      handler(val, old) {
        if (val && val !== old) {
          this.processedFriendsList = val;
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      // 好友列表loading
      friendsListLoading: false,
      // 搜索条件字段
      searchKey: "",
      searchPlaceholder: "\ue681",
      // 处理过的好友数据列表
      processedFriendsList: [],
    };
  },
  methods: {
    // 触发搜索
    triggerSearchEvent() {
      if (this.searchKey !== "") {
        this.processedFriendsList = this.processedFriendsList.filter((e) => {
          return (
            e.name.indexOf(this.searchKey) !== -1 ||
            e.uc_id.indexOf(this.searchKey) !== -1
          );
        });
      } else {
        this.processedFriendsList = this.friendsList;
      }
    },
    // 选择这个选项
    selectedThisFriend(item) {
      this.$emit("selectThisElement", item);
    },
  },
};
</script>
<style lang="stylus" scoped>
.containerBody
  flex 1;
  width 100%;
  min-height 0;
  box-sizing border-box;
  padding 0 15px;
  display flex;
  flex-flow column;
  align-items center;
  justify-content flex-start;
  .searchInputOuter
    width 100%;
    height 36px;
    flex-shrink 0;
    margin-bottom 15px;
    padding 0 15px;
    .searchInputBox
      width 100%;
      height 36px;
      border-radius 18px;
      background #F0F0F0;
      overflow hidden;
      box-sizing border-box;
      padding 0 15px;
      .inputStyle
        width 100%;
        height 36px;
        border-radius 18px;
  .placeholderMap
    flex 1;
    width 100%;
    min-height 0;
    overflow hidden;
  .friendContainer
    flex 1;
    width 100%;
    min-height 0;
    overflow-y auto;
    overflow-x hidden;
    scrollbar-width: none;
    &::-webkit-scrollbar
      width 0px;
    .blockTitle
      height 24px;
      user-select none;
      line-height 24px;
      text-align left;
      margin 20px 15px;
      background #F7F7F7;
      .titleText
        padding-left 14px;
        color #333333;
        font-size 14px;
    .childrenContainer
      width 100%;
      min-height 0;
      overflow hidden;
      .childrenItemContainer
        height 70px;
        width 100%;
        display flex;
        cursor pointer;
        align-items center;
        justify-content center;
        transition background .3s;
        &:hover
          background #F0F0F0
        .childrenItem
          height 50px;
          width 100%
          box-sizing border-box;
          padding 0 15px;
          display flex;
          align-items center;
          justify-content space-between;
          .leftContainer
            flex 1;
            height 100%;
            min-width 0;
            display flex;
            align-items center;
            justify-content flex-start;
            .avatarContainer
              width 50px;
              height 50px;
              flex-shrink 0;
              overflow hidden;
            .friendInfoContainer
              flex 1;
              min-width 0;
              height 50px;
              box-sizing border-box;
              padding-left 10px;
              display flex;
              flex-flow column;
              align-items center;
              justify-content center;
              .nameAndTagsContainer
                height 24px;
                width 100%;
                display flex;
                align-items center;
                justify-content flex-start;
                .nameContainer
                  height 100%;
                  max-width 50%;
                  color #333333;
                  font-size 14px;
                  font-weight bold;
                  line-height 24px;
                  flex-shrink 0;
                  text-align left;
                  overflow hidden;
                  text-overflow:ellipsis;
                  white-space: nowrap;
                  user-select none;
                .tagsContainer
                  flex 1;
                  min-width 0;
                  height 100%;
                  padding-left 10px;
                  box-sizing border-box;
                  text-align left;
                  overflow hidden;
                  text-overflow:ellipsis;
                  white-space: nowrap;
                  .tagsItem
                    height 100%;
                    color #1D4329;
                    line-height 24px;
                    text-align center;
                    font-size 12px;
                    padding 0 12px;
                    margin-right 8px;
                    border-radius 12px;
                    background #F2FCF8;
                    display inline-block;
              .IDContainer
                height 16px;
                width 100%;
                user-select none;
                line-height 16px;
                color #8F8F8F;
                font-size 12px;
                text-align left;
                overflow hidden;
                text-overflow:ellipsis;
                white-space: nowrap;
          .rightContainer
            width 20px;
            height 100%;
            display flex;
            flex-shrink 0;
            align-items center;
            justify-content center;
            .selectContainer
              width 20px;
              height 20px;
              border-radius 50%;
              flex-shrink 0;
              box-sizing border-box;
              background #FFFFFF;
              border 1px solid #CCCCCC;
            .hasSelected
              background #33CC66 !important;
              border 1px solid #33CC66 !important;
              display flex;
              align-items center;
              justify-content center;
              .iconStyle
                font-size 14px;
                color #FFFFFF;
</style>
