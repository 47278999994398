var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "placeholderMap" }, [
    _c(
      "div",
      { class: `placeholderImage_${_vm.placeholderType}` },
      [
        _c("el-image", {
          staticStyle: { width: "100%", height: "100%" },
          attrs: {
            src: require(`../../../assets/images/newPlaceHolder/placeholderImage_${_vm.placeholderType}.png`),
            fit: "contain"
          }
        })
      ],
      1
    ),
    _vm.placeholderText
      ? _c("div", { staticClass: "placeholderText" }, [
          _vm._v("\n    " + _vm._s(_vm.placeholderText) + "\n  ")
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }