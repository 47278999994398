<template>
  <div class="containerBody">
    <div class="searchInputOuter">
      <div class="searchInputBox">
        <el-input 
          class="inputStyle"
          v-model="searchKey" 
          :placeholder="searchPlaceholder"
          @keydown.enter.native="triggerSearchEvent"
        ></el-input>
      </div>
    </div>
    <div
      class="groupContainer"
      v-infinite-scroll="loadMoreData"
      infinite-scroll-disabled="loadDisabled"
      infinite-scroll-distance="70"
      infinite-scroll-immediate-check="false"
      v-show="groupList.length !== 0"
    >
      <ContactGroupItem
        v-for="(itemInfo,index) in groupList" 
        :key="itemInfo.contactId + itemInfo.isSelected + index" 
        :itemInfo="itemInfo"
        @selectThisElement="selectThisGroup"
      ></ContactGroupItem>
      <div 
        v-if="loadDisabled"
        class="loadingContainer"
        v-loading="loadDisabled"
        element-loading-spinner="el-icon-loading"
        element-loading-background="#FFFFFF"
      ></div>
    </div>
    <div v-show="groupList.length === 0" class="placeholderMap">
      <PlaceholderMap :placeholderType="2"></PlaceholderMap>
    </div>
  </div>
</template>
<script>
import ContactGroupItem from "./contactGroupItem";
import PlaceholderMap from "../placeholderMap";
export default {
  components: {
    ContactGroupItem,
    PlaceholderMap
  },
  props: {
    // 监听是否可以开始加载数据
    readyToLoadData: {
      type: Boolean,
      default: false
    },
    // 群组列表
    groupList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  watch: {
    readyToLoadData: {
      async handler(val,old) {
        if(val && val !== old) {
          await this.getGroupContactList();
        }
      },
      deep: true
    }
  },
  data() {
    return {
      // 是否禁止触发列表加载
      loadDisabled: false,
      // 搜索条件字段
      searchKey: '',
      searchPlaceholder: '\ue681',
      groupContactListPage: 1,
      groupContactListPageSize: 15,
      // 记录已经没有列表数据
      noDataFlag: false,
    }
  },
  methods: {
    // 触发搜索
    async triggerSearchEvent() {
      this.groupContactListPage = 1;
      this.noDataFlag = false;
      await this.getGroupContactList();
    },
    // 选中这个元素
    selectThisGroup(item) {
      this.$emit('selectThisElement',item)
    },
    async loadMoreData() {
      if(!this.noDataFlag) {
        this.loadDisabled = true;
        this.groupContactListPage++;
        await this.getGroupContactList();
        this.loadDisabled = false;
      }
    },
    // 开始加载群数据
    getGroupContactList() {
      return new Promise((resolve,reject) => {
        let groupSearchParams = {
          groupContactListPage: this.groupContactListPage,
          groupContactListPageSize: this.groupContactListPageSize,
          groupSearchKey: this.searchKey
        }
        this.$emit('getGroupContactList',groupSearchParams,() => {
          resolve("success")
        },() => {
          // 加载失败就将页面减1
          if(this.groupContactListPage > 1) {
            this.groupContactListPage--;
          }
          this.noDataFlag = true;
          resolve("success")
        });
      })
    }
  }
}
</script>
<style lang="stylus" scoped>
  .containerBody
    flex 1;
    width 100%;
    min-height 0;
    box-sizing border-box;
    padding 0 15px;
    display flex;
    flex-flow column;
    align-items center;
    justify-content flex-start;
    .searchInputOuter
      width 100%;
      height 36px;
      flex-shrink 0;
      margin-bottom 15px;
      padding 0 15px;
      .searchInputBox
        width 100%;
        height 36px;
        border-radius 18px;
        background #F0F0F0;
        overflow hidden;
        box-sizing border-box;
        padding 0 15px;
        .inputStyle
          width 100%;
          height 36px;
          border-radius 18px;
    .placeholderMap
      flex 1;
      width 100%;
      min-height 0;
      overflow hidden;
    .groupContainer
      flex 1;
      width 100%;
      min-height 0;
      scrollbar-width: none;
      overflow-y auto;
      overflow-x hidden;
      &::-webkit-scrollbar
        width 1px;
      .loadingContainer
        height 70px;
        width 100%;
        display flex;
        align-items center;
        justify-content center;

</style>