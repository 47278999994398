var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "containerBody" },
    [
      _c("div", { staticClass: "searchInputOuter" }, [
        _c(
          "div",
          { staticClass: "searchInputBox" },
          [
            _c("el-input", {
              staticClass: "inputStyle",
              attrs: { placeholder: _vm.searchPlaceholder },
              on: { input: _vm.triggerSearchEvent },
              model: {
                value: _vm.searchKey,
                callback: function($$v) {
                  _vm.searchKey = $$v
                },
                expression: "searchKey"
              }
            })
          ],
          1
        )
      ]),
      _vm.handleFriendsList.length !== 0
        ? [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.friendsListLoading,
                    expression: "friendsListLoading"
                  }
                ],
                staticClass: "friendContainer",
                attrs: {
                  "element-loading-spinner": "el-icon-loading",
                  "element-loading-background": "#FFFFFF"
                }
              },
              [
                _vm._l(_vm.handleFriendsList, function(item) {
                  return [
                    item.name_first_spell != "self"
                      ? _c(
                          "div",
                          {
                            key: item.name_first_spell,
                            staticClass: "blockTitle"
                          },
                          [
                            _c("span", { staticClass: "titleText" }, [
                              _vm._v(_vm._s(item.name_first_spell))
                            ])
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "childrenContainer" },
                      [
                        _vm._l(item.childrens, function(children, index) {
                          return [
                            _c(
                              "div",
                              {
                                key: index,
                                staticClass: "childrenItemContainer",
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.selectedThisFriend(children)
                                  }
                                }
                              },
                              [
                                _c("div", { staticClass: "childrenItem" }, [
                                  _c("div", { staticClass: "leftContainer" }, [
                                    _c(
                                      "div",
                                      { staticClass: "avatarContainer" },
                                      [
                                        _c("Avatar", {
                                          attrs: {
                                            path: children.avatar,
                                            international:
                                              children.country !== ""
                                                ? Number(children.country)
                                                : 0,
                                            disableClick: true
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "friendInfoContainer" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "nameAndTagsContainer"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "nameContainer",
                                                class: children.vipStatus
                                                  ? "vipName"
                                                  : ""
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        " +
                                                    _vm._s(children.userName) +
                                                    "\n                      "
                                                )
                                              ]
                                            ),
                                            children.vipStatus
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "vipIcon",
                                                    staticStyle: {
                                                      display: "flex",
                                                      "align-items": "center"
                                                    }
                                                  },
                                                  [
                                                    _c("el-image", {
                                                      staticStyle: {
                                                        width: "16px",
                                                        "margin-top": "8px"
                                                      },
                                                      attrs: {
                                                        src: require("../../../assets/images/pay/masonry.png"),
                                                        fit: "fill"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            children.tags &&
                                            children.tags.length !== 0
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "tagsContainer"
                                                  },
                                                  _vm._l(
                                                    children.tags,
                                                    function(tag) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: tag,
                                                          staticClass:
                                                            "tagsItem"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          " +
                                                              _vm._s(tag) +
                                                              "\n                        "
                                                          )
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _vm._e()
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "IDContainer" },
                                          [
                                            _vm._v(
                                              "\n                      ID: " +
                                                _vm._s(children.uc_id) +
                                                "\n                    "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]),
                                  _c("div", { staticClass: "rightContainer" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "selectContainer",
                                        class: children.isSelected
                                          ? "hasSelected"
                                          : ""
                                      },
                                      [
                                        children.isSelected
                                          ? _c("span", {
                                              staticClass:
                                                "iconfont_Me icon-check iconStyle"
                                            })
                                          : _vm._e()
                                      ]
                                    )
                                  ])
                                ])
                              ]
                            )
                          ]
                        })
                      ],
                      2
                    )
                  ]
                })
              ],
              2
            )
          ]
        : _c(
            "div",
            { staticClass: "placeholderMap" },
            [_c("PlaceholderMap", { attrs: { placeholderType: 2 } })],
            1
          )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }