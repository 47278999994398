var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "groupItem",
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.selectThisElement.apply(null, arguments)
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "avatarContainer" },
        [
          _c("Avatar", {
            attrs: {
              path: _vm.itemInfo.header || _vm.itemInfo.profilePic,
              international: 0,
              isGroupHeader: true,
              disableClick: true
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "itemContainer" }, [
        _c("div", { staticClass: "containerInner" }, [
          _c("div", { staticClass: "innerFirstLine" }, [
            _c("div", { staticClass: "itemName" }, [
              _vm._v(_vm._s(_vm.itemInfo.name))
            ]),
            _c("div", { staticClass: "groupTypeIcon" }, [
              _vm.itemInfo.accessPattern === "1"
                ? _c("span", {
                    staticClass: "iconfont_Me icon-open-fill iconStyle",
                    style: { color: "#333333" }
                  })
                : _vm._e(),
              _vm.itemInfo.accessPattern === "2"
                ? _c("span", {
                    staticClass: "iconfont_Me icon-member-fill iconStyle",
                    style: { color: "#FFC526" }
                  })
                : _vm._e()
            ])
          ]),
          _c("div", { staticClass: "innerSecondLine" }, [
            _c("span", { staticClass: "iconfont_Me icon-user-fill iconStyle" }),
            _vm._v("\n        " + _vm._s(_vm.itemInfo.memberCount) + "\n      ")
          ])
        ])
      ]),
      _c(
        "div",
        {
          staticClass: "selectContainer",
          class: _vm.itemInfo.isSelected ? "hasSelected" : ""
        },
        [
          _vm.itemInfo.isSelected
            ? _c("span", { staticClass: "iconfont_Me icon-check iconStyle" })
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }