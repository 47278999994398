var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "containerBody" }, [
    _c("div", { staticClass: "searchInputOuter" }, [
      _c(
        "div",
        { staticClass: "searchInputBox" },
        [
          _c("el-input", {
            staticClass: "inputStyle",
            attrs: { placeholder: _vm.searchPlaceholder },
            nativeOn: {
              keydown: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.triggerSearchEvent.apply(null, arguments)
              }
            },
            model: {
              value: _vm.searchKey,
              callback: function($$v) {
                _vm.searchKey = $$v
              },
              expression: "searchKey"
            }
          })
        ],
        1
      )
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "infinite-scroll",
            rawName: "v-infinite-scroll",
            value: _vm.loadMoreData,
            expression: "loadMoreData"
          },
          {
            name: "show",
            rawName: "v-show",
            value: _vm.groupList.length !== 0,
            expression: "groupList.length !== 0"
          }
        ],
        staticClass: "groupContainer",
        attrs: {
          "infinite-scroll-disabled": "loadDisabled",
          "infinite-scroll-distance": "70",
          "infinite-scroll-immediate-check": "false"
        }
      },
      [
        _vm._l(_vm.groupList, function(itemInfo, index) {
          return _c("ContactGroupItem", {
            key: itemInfo.contactId + itemInfo.isSelected + index,
            attrs: { itemInfo: itemInfo },
            on: { selectThisElement: _vm.selectThisGroup }
          })
        }),
        _vm.loadDisabled
          ? _c("div", {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadDisabled,
                  expression: "loadDisabled"
                }
              ],
              staticClass: "loadingContainer",
              attrs: {
                "element-loading-spinner": "el-icon-loading",
                "element-loading-background": "#FFFFFF"
              }
            })
          : _vm._e()
      ],
      2
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.groupList.length === 0,
            expression: "groupList.length === 0"
          }
        ],
        staticClass: "placeholderMap"
      },
      [_c("PlaceholderMap", { attrs: { placeholderType: 2 } })],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }