<template>
  <div class="personItem" @click.stop="selectThisElement">
    <div class="avatarContainer">
      <Avatar
        :path="itemInfo.profilePic"
        :international="
          itemInfo.countryCode !== '' ? Number(itemInfo.countryCode) : 0
        "
        :isGroupHeader="itemInfo.chatType == 'groupChat'"
        :disableClick="true"
      ></Avatar>
    </div>
    <div class="itemContainer">
      <div class="containerInner">
        <div class="innerFirstLine">
          <div
            class="itemName"
            :style="{ maxWidth: itemInfo.chatType == 'groupChat' ? '80%' : '' }"
            :class="itemInfo.vipStatus ? 'vipName' : ''"
          >
            {{ itemInfo.name }}
          </div>
          <div
            class="vipIcon"
            style="display:flex;align-items:center"
            v-if="itemInfo.vipStatus"
          >
            <el-image
              style="width: 16px;margin-top: 8px;"
              :src="require('../../../assets/images/pay/masonry.png')"
              fit="fill"
            >
            </el-image>
          </div>
          <div class="tagsBox" v-if="tags !== 0 && itemInfo.chatType == 'chat'">
            <div class="tagItem" v-for="tag in tags" :key="tag">{{ tag }}</div>
          </div>
        </div>
        <div
          class="innerSecondLineForPerson"
          v-if="itemInfo.chatType == 'chat'"
        >
          ID: {{ itemInfo.ucId }}
        </div>
        <div
          class="innerSecondLineForGroup"
          v-if="itemInfo.chatType == 'groupChat'"
        >
          <span class="iconfont_Me icon-user-fill iconStyle"></span>
          {{ itemInfo.memberCount }}
        </div>
      </div>
    </div>
    <div
      class="selectContainer"
      :class="itemInfo.isSelected ? 'hasSelected' : ''"
    >
      <span
        v-if="itemInfo.isSelected"
        class="iconfont_Me icon-check iconStyle"
      ></span>
    </div>
  </div>
</template>
<script>
import Avatar from "../avatarDisplay";
export default {
  components: {
    Avatar,
  },
  props: {
    itemInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    tags() {
      let handleTags = this.itemInfo.tags.filter((item) => {
        return item !== "";
      });
      return handleTags;
    },
  },
  methods: {
    // 选中这个元素
    selectThisElement() {
      this.$emit("selectThisElement", this.itemInfo);
    },
  },
};
</script>
<style lang="stylus" scoped>
.personItem
  height 70px;
  width 100%;
  display flex;
  user-select none;
  cursor pointer;
  padding 0 15px;
  align-items center;
  justify-content flex-start;
  box-sizing border-box;
  transition background .3s;
  &:hover
    background #F0F0F0
  .avatarContainer
    width 50px;
    height 50px;
    flex-shrink 0;
  .itemContainer
    flex 1;
    height 50px;
    min-width 0;
    display flex;
    align-items center;
    justify-content flex-start;
    .containerInner
      height 40px;
      width 100%;
      box-sizing border-box;
      padding-left 10px;
      .innerFirstLine
        width 100%;
        height 24px;
        overflow hidden;
        display flex;
        align-items center;
        justify-content flex-start;
        .itemName
          height 24px;
          max-width 50%;
          flex-shrink 0;
          font-size 14px;
          font-weight bold;
          color #333333;
          overflow hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
        .tagsBox
          flex 1;
          height 24px;
          min-width 0;
          padding-left 15px;
          box-sizing border-box;
          line-height 24px;
          text-align left;
          overflow hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
          .tagItem
            height 24px;
            font-size 12px;
            color #1D4329;
            line-height 24px;
            padding 0 15px;
            margin-right 8px;
            border-radius 12px;
            background #F2FCF8;
            display inline-block;
      .innerSecondLineForPerson
        width 100%;
        height 16px;
        line-height 16px;
        text-align left;
        font-size 12px;
        color #8F8F8F;
        overflow hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
      .innerSecondLineForGroup
        width 100%;
        height 16px;
        font-size 12px;
        color #8F8F8F;
        overflow hidden;
        display flex;
        align-items center;
        justify-content flex-start;
        .iconStyle
          color #8F8F8F;
          font-size 16px;
  .selectContainer
    width 20px;
    height 20px;
    border-radius 50%;
    flex-shrink 0;
    box-sizing border-box;
    background #FFFFFF;
    border 1px solid #CCCCCC;
  .hasSelected
    background #33CC66 !important;
    border 1px solid #33CC66 !important;
    display flex;
    align-items center;
    justify-content center;
    .iconStyle
      font-size 14px;
      color #FFFFFF;
</style>
